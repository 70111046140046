import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ArticleHeader from '../components/page/Article/ArticleHeader';
import ContentText from '../components/page/Content/ContentText';
import ContentImage from '../components/page/Content/ContentImage';
import ArticleWrapper from '../components/page/Article/ArticleWrapper';

const Article = ({ data }) => {
  // Props
  const article = data.prismicArticle.data;

  if (!article) return null;

  const body = article.body.map((slice, index) => {
    switch (slice.slice_type) {
      case ('image'):
        return <ContentImage key={'slice-' + index} slice={slice} />;
      case ('text'):
        return <ContentText key={'slice-' + index} slice={slice} />;
      default:
        return null;
    }
  })

  return (
    <Layout>
      <SEO 
        title={article.meta_title}
        description={article.meta_description}
        image={article.featured_image}
        type='article'
      />
      <ArticleWrapper>
        <ArticleHeader 
          title={article.title}
          description={article.description}
          alt={article.featured_image.alt}
          image={article.featured_image}
        />
        {body}
      </ArticleWrapper>
    </Layout>
  );
};

export default Article;

export const query = graphql`
  query Article($id: String!) {
    prismicArticle(id: {eq: $id}) {
      data {
        body {
          ... on PrismicArticleBodyText {
            id
            slice_label
            slice_type
            primary {
              text {
                html
                raw
                text
              }
            }
          }
          ... on PrismicArticleBodyImage {
            id
            slice_label
            slice_type
            primary {
              large_width
              image {
                alt
                fluid(maxWidth: 1920) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
        description {
          text
          raw
          html
        }
        featured_image {
          alt
          url
          dimensions {
            height
            width
          }
          fluid(maxWidth: 1920) {
            ...GatsbyPrismicImageFluid
          }
        }
        meta_description
        meta_title
        title {
          html
          raw
          text
        }
      }
    }
  }
`;