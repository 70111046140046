import React from 'react';
import { RichText } from 'prismic-reactjs';
import { Container, Title, Description, FeaturedImage } from './styles';

const PostHeader = ({ title, description, image, alt }) => {
  let descriptionText;
  if (description) {
    descriptionText = (
      <Description>
        {RichText.asText(description.raw)}
      </Description>
    );
  }
  return (
    <header>
      {image && <FeaturedImage fluid={image.fluid} alt={alt} />}
      <Container>
        <Title>
          {RichText.asText(title.raw)}
        </Title>
        {descriptionText}
      </Container>
    </header>
  );
};

export default PostHeader;