import styled from '@emotion/styled';
import Img from 'gatsby-image';
import { breakpoints, dimensions } from '../../../../utils/styles';

export const Container = styled.div`
  max-width: ${dimensions.contentWidth};
  margin: 0 auto;
  padding: 32px 24px;

  @media (min-width: ${breakpoints.xl}px){
    max-width: 850px;
  }
`;

export const Title = styled.h1`
  font-size: 48px;

  @media (max-width: ${breakpoints.l}px){
    font-size: 36px;
  }
`;

export const Description = styled.p`
  font-size: 24px;
  line-height: 28px;
  margin: 0;
`;

export const FeaturedImage = styled(Img)`
  max-width: ${dimensions.contentWidth};
  margin: 0 auto;
`;